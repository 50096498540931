/* @media screen and (min-device-width: 1200px) { */
/* Hero section coding start */

@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');


/* loding start coding */
.loder_first__section{
  background: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loder__Section__main{
  background: rgb(1, 1, 1) !important;
  height: 100vh;
  z-index: 2000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
.loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: #fff;
  position: relative;
  margin: 0 32px;
}

.loading-container:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  animation: movingLine 2.4s infinite ease-in-out;
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

@media screen and(max-width:640px) {
  .loading-container {
    width: 100%;
    max-width:100% !important;
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 32px;
  }
}
.loading-text {
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
}

.loading-text span {
  animation: moveLetters 2.4s infinite ease-in-out;
  transform: translateX(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

.socials {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
}




/* end loder coding */

a {
  text-decoration: none;
}

.slider_full_body_section {
  position: relative;
}

.slider_full_body_section .slider_section_main {
  position: relative;
  height: 100vh;
}

.slider_full_body_section .slider_section_main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Hero_section_main {
  padding-top: 230px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.slider_section_div {
  padding-top: 20px;
  color: white;
  font-family: "Roboto", serif;
  font-weight: 400;
}

.slider_section_div p {
  color: white;
  font-size: 16px;
}

.Hero_section_main_hero {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.Hero_section_main_hero .Slider__bg_hero_section {
  height: 100%;
  width: 100%;
}

.Hero_section_main_hero .Heading_hero_top_section {
  padding-left: 0 !important;
  padding-bottom: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  font-size: x-small;
  font-family: "Roboto", serif;
  font-size: clamp(1rem, 2vw + 2vh, 1.5rem);
  color: white;
  --animate-duration: 2s;
}

.Hero_section_main_hero .Heading_hero_top_section h2 {
  font-size: clamp(1rem, 3vw + 2vh, 5.6rem);
  color: #ffffff;
  font-family: "Roboto", serif;
}


.Search_section_div {
  height: 110px;
  padding: 0;
  margin: 0;
  padding: 2px;
}

.Search_section_div .Budget_section_mian {
  background: #fffcfc;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 90px;
}

.Search_section_div .Budget_section_mian .Budgest_icon_section {
  display: flex;
  align-items: center;
  gap: 27px;
}

.Search_section_div .Budget_section_mian .Budgest_icon_section h5 {
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.Budget_section_mian_chooose {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
}

.Budget_section_mian_chooose select {
  padding: 10px;
  outline: none;
  border: none;
}

.Search_section_div .Budget_section_mian .Subbmit_button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.Search_section_div .Budget_section_mian .Subbmit_button h6 {
  font-weight: 700;
  font-size: 1.2rem;
  color: white;
  padding: 0;
  margin: 0;
  padding-right: 20px;
}

.Home_submit_btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.Home_submit_btn .search_icons {
  color: white;
}

.Subbmit_button .Home_submit_btn .ArrowRightAltIcon {
  font-size: 30px;
  color: white;
  margin-top: 10px;
}

.Subbmit_button .Home_submit_btn:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.Subbmit_button .Home_submit_btn .ArrowRightAltIcon {
  color: white;
  font-weight: 800;
}

.Subbmit_button .Home_submit_btn:hover .ArrowRightAltIcon {
  transition: transform 0.5s ease;
  transform: rotate(45deg);
  color: white;
}

.Budget_section_mian {
  border-radius: 8px;
}

/* About section coding start */
.About_section_main {
  background: url("../public/Images/Images_Exprense_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 90px;
  padding-bottom: 100px;
}

.Div_section_about_section_main {
  background: white;
  padding: 10px;
}

.Div_section_about_section_main .Icon_section {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: x-large;
  font-weight: 400;
  font-family: "Roboto", serif;
}

.Seating {
  display: flex;
  gap: 30px;
  font-family: "Roboto", serif;
}

.Expreanse_section_main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Expreance_text_section {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

.Expreance_text_section h1 {
  font-size: clamp(1rem, 2.7vw + 2vh, 3.5rem);
  font-family: "Roboto", serif;
  letter-spacing: 1px;
}

.Expreance_text_section p {
  font-size: clamp(1rem, 0.4vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;
  padding-top: 20px;
}

.Expreanse_section_main .Exprteans_section {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Expreanse_section_main .Exprteans_section img {
  padding-top: 0px;
}

.Expreanse_section_main .Exprteans_section .Icons_Expreansces {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Expreanse_section_main .Exprteans_section .Icons_Expreansces h1 {
  font-size: clamp(1rem, 2.7vw + 2vh, 3.5rem);
  font-family: "Roboto", serif;
  font-weight: 700;
  padding-bottom: 0;
  font-size: 30px;
}

.Expreanse_section_main .Exprteans_section p {
  padding-top: 0;
}

.Button_Exp_slider {
  display: flex;
  justify-content: end;
  gap: 30px;
  align-items: center;
  padding-top: 30px;
}

.Button_Exp_slider button {
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  background: black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: color 1s, background 1s;
}

.Button_Exp_slider button:hover {
  color: black;
  border: 1px solid black;
  background: transparent;
  width: 50px;
  transition: 1s;
}

.Button_Exp_slider button .KeyboardBackspaceIcon {
  transition: transform 1s ease;
}

.Button_Exp_slider button:hover .KeyboardBackspaceIcon {
  transform: translateX(-10px);
}

.Button_Exp_slider button .ArrowRightAltIcon {
  transition: transform 1s ease;
  padding-left: 0;
}

.Button_Exp_slider button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.slider_images {
  padding: 0;
  margin: 0;
}

.slider_images img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

/* Gallery section main */
.Gallery_heding_section_main {
  background: url("../public/Images/Gellary_bg_images.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Gallery_heding_section_main .container-fluid .row .col-md-6 {
  padding: 0;
  margin: 0;
}

.Gallery_heding_section_main .container-fluid .row .col-md-6 h6 {
  color: #fbae16;
  font-family: "Roboto", serif;
  font-size: clamp(1rem, 2vw + 2vh, 1.5rem);
  padding-left: 10px;
}

.Gallery_heding_section_main .container-fluid .row .col-md-6 h1 {
  font-size: clamp(1rem, 2.5vw + 2vh, 3.5rem);
  font-weight: 500;
  font-family: "Roboto", serif;
  padding-left: 10px;
}

.Gallery_heding_section_main .container-fluid .row .col-md-12 .gallery {
  padding-top: 40px;
}

.Gallery_heding_section_main .container-fluid .row .col-md-12 p {
  font-size: clamp(1rem, 1vw + 2vh, 1.5rem);
  font-family: "Roboto", serif;
  padding-left: 10px;
}

.container-fluid .row .col-12 {
  padding: 0;
  margin: 0;
  overflow: hidden;
  padding: 10px;
}

.accordion {
  width: 100%;
  height: auto;
  /* height: 450px; */
  overflow: hidden;
  margin: 0px auto;
}

.accordion ul {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
}

.accordion ul li {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 16.666%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 1000ms ease-in-out;
}

.accordion ul li div {
  display: block;
  overflow: hidden;
  width: 100%;
}

.accordion ul li:nth-child(1) {
  background-image: url("../public/Images/Whatsapp__gallery__images\ \(1\).jpg");
  background-position: 10% 30%;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion ul li:nth-child(2) {
  background-position: 70% 0%;
  background-image: url("../public/Images/Whatsapp__gallery__images\ \(9\).jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion ul li:nth-child(3) {
  background-image: url("../public/Images/Whatsapp__gallery__images\ \(8\).jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion ul li:nth-child(4) {
  background-image: url("../public/Images/Whatsapp__gallery__images\ \(7\).jpg");
  background-position: 30% 40%;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion ul li:nth-child(5) {
  background-image: url("../public/Images/Whatsapp__gallery__images\ \(6\).jpg");
  background-position: 60% 64%;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion ul li {
  background-size: cover;
}

.accordion ul:hover li {
  width: 10%;
}

.accordion ul:hover li:hover {
  width: 20%;
  filter: grayscale(0%);
}

.accordion ul:hover li:hover a * {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.accordion ul:hover li:hover .title * {
  opacity: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.accordion ul li {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 16.666%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 1000ms ease-in-out;
}


.accordion ul li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0);
  transition: background-color 300ms ease;
  z-index: 1;
}



.container-fluid .row .col-md-8 {
  padding: 0;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 40px;
}

.container-fluid .row .col-md-8 h1 {
  font-size: clamp(1rem, 1.5vw + 1.5vh, 3rem);
  font-family: "Roboto", serif;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left !important;
  font-weight:700 !important;
}

.container-fluid .row .col-md-8 h5 {
  font-size: clamp(1rem, 1.5vw + 1vh,2rem);
  font-family: "Roboto", serif;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left !important;

}

.container-fluid .row .col-md-8 .Out_tem_info {
  font-size: clamp(1rem, 1vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
  padding-left: 10px;
  text-align: justify;
  padding-left: 10px;
  padding-right: 20px;
}

.container-fluid .row .col-md-8 p {
  font-size: clamp(1rem, 1vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
  padding-left: 10px;
  text-align: left;
}

.container-fluid .row .col-md-4 {
  padding: 0;
  margin: 0;
  padding-bottom: 40px;
}

.container-fluid .row .col-md-4 .Testimonial_images {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Testimonial_section_slider {
  padding: 10px;
}

.slider_main {
  padding-bottom: 40px;
}

.Testimonial_section_slider .Testimonial_section_slider_items {
  background: #ffffff;
  width: 100%;
  padding: 20px;
  box-shadow: rgba(108, 108, 117, 0.1) 0px 7px 29px 0px;
  border-radius: 12px;
  height: 250px;
  padding-top: 20px;
}

.Testimonial_section_slider .Testimonial_section_slider_items p {
  font-size: 1.2rem;
  font-family: "Roboto", serif;
  padding-top: 10px;
}

.brand___patner__main {
  font-size: 1.2rem;
  font-family: "Roboto", serif;
}

.Testimonial_section_slider .Testimonial_section_slider_items .Testimonial_name {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Testimonial_section_slider .Testimonial_section_slider_items .Testimonial_name .Testimonil_images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Testimonial_section_slider .Testimonial_section_slider_items .Testimonial_name .Testimonil_images img {
  width: 60px;
  height: 60px;
}

.Testimonial_section_slider .Testimonial_section_slider_items .Testimonial_name .Testimonial_name_heading h5 {
  padding: 0;
  margin: 0;
}

.Testimonial_section_slider .Testimonial_section_slider_items .Testimonial_name .Testimonial_name_heading p {
  padding: 0;
  margin: 0;
  color: #8e8e8e;
}

.swiper-pagination-bullet {
  background: red;
}

/* Brand_partner  */

.Brand_partner {
  padding-top: 0px;
}

.Slider_full_section {
  background: #f2f2e3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Brand_partner_slider {
  padding-bottom: 0px;
}

div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;

  div.highway-barrier {
    overflow: hidden;
    position: relative;
  }

  ul.highway-lane {
    display: flex;
    height: 100%;

    li.highway-car {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

#stffull div.highway-barrier {
  ul.highway-lane {
    width: 500%;

    li.highway-car {
      animation: translatestf 30s linear infinite;

      h4 {
        font-size: 28px;
      }
    }
  }
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}

#infinite div.highway-barrier {

  &::before,
  &::after {
    content: " ";
    position: absolute;
    z-index: 9;
    width: 150px;
    height: 100%;
  }

  &::before {
    top: 0;
    left: 0;
    background: linear-gradient(277deg,
        rgba(238, 237, 218, 0.411) 45%,
        rgba(243, 247, 199, 0.589) 100%);
  }

  &::after {
    top: 0;
    right: 0;
    background: linear-gradient(277deg,
        rgba(247, 243, 208, 0.555) 14%,
        rgba(0, 0, 0, 0) 100%);
  }

  ul.highway-lane {
    width: calc(180px * 24);

    li.highway-car {
      width: 150px;
      animation: translateinfinite 25s linear infinite;

      span.fab {
        font-size: 65px;
      }
    }
  }
}

/* Blog_main_section start */

.Blog_main_section {
  padding-top: 40px;
}

.Blog_main_section .Testmonial_section_main h1 {
  font-size: clamp(1rem, 2.5vw + 2vh, 3.5rem);
  padding: 0;
  margin: 0;
}

.Blog_section_slider {
  border: 1px solid #8e8e8e;
  border-radius: 12px;
  padding: 3px;
}

.Blog_section_slider .Blog_section_slider_items {
  padding: 3px;
}

.Blog_section_slider .Blog_section_slider_items .Blog_section_slider_Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.Blog_section_slider .Blog_section_slider_items .Blog_header {
  color: #8e8e8e;
  padding-top: 20px;
  font-size: 1.2rem;
  font-family: "Roboto", serif;
  padding-bottom: 0;
}

.Blog_section_slider .Blog_section_slider_items .Dec_section_blog {
  font-family: "Roboto", serif;
  padding-bottom: 0;
  font-size: 1.2rem;
  padding-top: 0;
}

.Blog_section_slider_items button {
  background: transparent;
  padding: 10px;
  font-weight: 400;
  border: none;
  color: #fbae16;
  display: flex;
  align-items: center;
}

.Blog_section_slider_items button:hover {
  color: #8e8e8e;
}

.Blog_section_slider_items button .ArrowRightAltIcon {
  transition: transform 0.3s ease;
  margin-left: 5px;
}

.Blog_section_slider_items button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

/* FAQ_main_section saection start */
.Faq_section {
  background: #ecece7;
  padding-top: 40px;
  padding-bottom: 40px;
}

.FAQ_main_section .col-12 h1 {
  width: 100%;
  font-family: "Roboto", serif;
}

.FAQ_main_section .col-12 h5 {
  font-family: "Roboto", serif;
  padding-top: 5px;
  padding-bottom: 40px;
}

.accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: transparent;
}

.accordion-button {
  background-color: #ecece7;
}

.accordion-item {
  border-radius: none;
  border-top: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
  border-left: none;
  border-right: none;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item:last-of-type>.accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Header_section_faq h6 {
  padding-bottom: 2px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  font-size: larger;
  font-family: "Roboto", serif;
}

.Body_text_section p {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  font-family: "Roboto", serif;
}

/* About_section_main section*/
.About_section_main_footer {
  background: url("../public/Images/About_riana_images_bg.png");
  padding-top: 180px;
  padding-bottom: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.About_section_main_footer .container-fluid .row .col-12 {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About_main_footer {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

.About_section_main_footer .About_Footer_section {
  background: #ecece7;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-bottom: 30px;
}

.About_Footer_section h1 {
  font-family: "Roboto", serif;
  font-weight: 400;
}

.About_Footer_section p {
  font-family: "Roboto", serif;
  font-size: 1.2rem;
}

.About_section_main_footer .Submit_section {
  background: #ffffff;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.About_section_main_footer .Submit_section div {
  width: 180px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  background: #fbae16;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}

.About_section_main_footer .Submit_section div:hover {
  width: 180px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #fbae16;
  background: transparent;
  border-radius: 12px;
  color: #fbae16;
  cursor: pointer;
  transition: 1s;
}

.About_section_main_footer .Submit_section div:hover .ArrowRightAltIcon {
  transition: transform 0.5s ease;
  transform: rotate(45deg);
}

.About_section_main_footer .Submit_section div {
  font-size: larger;
}

.About_section_main_footer .Submit_section div .ArrowRightAltIcon {
  padding-bottom: 10px;
  font-size: 50px;
  padding-top: 10px;
}

/* about section main */
.About_section_main_all {
  background: #253037;
  padding-top: 120px;
  padding-bottom: 100px;
}

.About_section_ttete {
  padding-top: 100px;
}

.About_section_main_all .About_section_text {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.About_section_main_all .About_section_text button {
  background: #fbae16;
  border-radius: 8px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-family: "Roboto", serif;
  font-weight: 400;
  width: 50%;
}

.About_section_main_all .About_section_text button:hover {
  background: transparent;
  color: #fbae16;
  border: 1px solid #fbae16;
  transition: transform 1s ease;
}

.About_section_main_all .About_section_text button .ArrowRightAltIcon {
  transition: transform 1s ease;
}

.About_section_main_all .About_section_text button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.About_section_main_all .About_section_text button .ArrowRightAltIcon {
  color: white;
  font-weight: 800;
}

.About_section_main_all .About_section_text button:hover .ArrowRightAltIcon {
  transition: transform 0.5s ease;
  transform: rotate(45deg);
  color: #fbae16;
}

.About_section_main_all .About_section_text h1 {
  font-family: "Roboto", serif;
  font-size: clamp(1rem, 2.5vw + 2vh, 3.5rem);
}

.About_section_main_all .About_section_text p {
  font-family: "Roboto", serif;
  font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
}

.About_section_main_all .About_section_images {
  padding: 0;
  margin: 0;
}

.About_section_main_all .About_section_images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 10px;
  border-radius: 22px;
}

.About_section_main_secound {
  background: url("../public/Images/Images_Exprense_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40px;
}

.About_section_main_secound .About_section_secound .row .col-12 {
  padding: 0;
  margin: 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  flex-direction: column;
  padding-top: 70px;
}

.About_section_main_secound .About_section_secound .row .col-12 h1 {
  font-family: "Roboto", serif;
  font-weight: 400;
}

.About_section_main_secound .About_section_secound .row .col-12 p {
  text-align: left;
  font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
  padding-bottom: 20px;
}

.About_section_main_secound .About_section_secound .row .col-12 ul li {
  text-align: center;
  font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
  padding-bottom: 20px;
}

.Expreanse_About_section_main {
  display: flex;
  justify-content: space-around;
}

.Expreanse_About_section_main .Project_section p {
  text-align: center;
  padding-left: 40px;
  font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
}

.Expreanse_About_section_main .Expreanse {
  display: flex;
  align-items: center;
  gap: 30px;
}

.Expreanse_About_section_main .Expreanse h1 {
  padding: 0;
  margin: 0;
  font-family: "Roboto", serif;
  font-weight: 400;
}

.About_section_thard {
  background: url("../public/Images/About_section_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.About_section_thard .About_section_th {
  padding-top: 50px;
  padding-bottom: 50px;
}

.About_section_thard .About_section_th .row .col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  padding: 10px;
}

.About_section_thard .About_section_th .row .col-md-6 h1 {
  font-family: "Roboto", serif;
  font-weight: 400;
  text-align: left;
  padding-left: 0;

}

.About_section_thard .About_section_th .row .col-md-6 ul li {
  font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
  font-family: "Roboto", serif;
  text-align: left;
  padding-right: 5px;
}

.About_section_thard .About_section_th .row .col-md-6 img {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  object-fit: cover;
}

.About_images_sectionsss {
  height: 300px;
}

.About_images_sectionsss .Images_section {
  padding: 0;
  margin: 0;
  height: 100%;
  padding-left: 0;
  height: 450px;
  position: relative;
}

.About_images_sectionsss .Images_section img {
  width: 100%;
  height: 100%;
  z-index: 9999;
  object-fit: cover;
  position: absolute;
  padding-left: 3px;
  padding-right: 20px;
}

.Our_teams_section_main {
  background: url("../public/Images/Images_Exprense_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 50px;
}

.Our_team_section_main .row .col-12 {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.Our_team_section_main .row .col-12 h1 {
  padding-top: 100px;
  text-align: center;
  font-family: "Roboto", serif;
  font-weight: 400;
  padding-bottom: 0;
}

.Our_team_section_main .row .col-12 .row .col-md-4 .Images_section {
  padding-left: 13px;
}

.Our_team_section_main .row .col-12 .row .col-md-4 .Images_section .Images_section_all img {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 500px;
}

.Images_section_all .Over_section_main {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.Images_section_all .Over_section_main h5 {
  padding: 0;
  margin: 0;
  padding-top: 20px;
  font-family: "Roboto", serif;
}

.Images_section_all .Over_section_main p {
  font-family: "Roboto", serif;
  padding-bottom: 0;
}

.Images_section_all .Over_section_main .Icons_section {
  display: flex;
  gap: 30px;
}

.Images_section_all .Over_section_main .Icons_section i {
  color: #fbae16;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
}

/* Services_section section start  */
.Services_section_maain {
  background: url("../public/Images/Services_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 250px;
  padding-bottom: 100px;
}

.Services_section_maain .Services_section .row .col-12 {
  padding: 0;
  margin: 0;
}

.Services_section_maain .Services_section .row .col-12 h1 {
  padding: 0;
  margin: 0;
  color: white;
  font-family: "Roboto", serif;
  padding-left: 10px;

}

.Services_section_maain .Services_section .row .col-12 p {
  color: white;
  font-family: "Roboto", serif;
  padding-left: 10px;
}

.services_section_all {
  padding: 0;
  margin: 0;
}

.services_section_all .Services_section {
  height: 100%;
  position: relative;
  padding: 10px;
}

.services_section_all .Services_section img {
  width: 100%;
  height: 100%;
}

.services_section_all .Services_section h5 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: black;
}

.services_section_all .Services_section button {
  float: right;
  position: absolute;
  bottom: 10px;
  right: 12px;
  height: 80px;
  width: 80px;
  border-top-left-radius: 70%;
  border: none;
  background: black;
}

.services_section_all .Services_section button .ArrowRightAltIcon {
  transition: transform 1s ease;
}

.services_section_all .Services_section button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.services_section_all .Services_section button .ArrowRightAltIcon {
  color: white;
  font-weight: 800;
}

.services_section_all .Services_section button:hover .ArrowRightAltIcon {
  transition: transform 0.5s ease;
  transform: rotate(45deg);
}

/* key Pointes secti0on main */
.Key_pointes_section {
  background: #ecece7;
  padding-top: 80px;
  padding-bottom: 80px;
}

.Key_pointes_section h1 {
  padding: 0;
  margin: 0;
  color: black;
  font-family: "Roboto", serif;
  padding-bottom: 30px;
}

.Key_pointes_section h6 {
  font-family: "Roboto", serif;
  font-size: 1.3rem;
  border-bottom: 1px solid #253037;
  padding-top: 20px;
}

/* INDUSTRY_BASED_VALUE section */
.INDUSTRY_BASED_VALUE {
  padding-bottom: 50px;
  padding-top: 50px;
  background: url("../public/Images/Images_Exprense_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.INDUSTRY_BASED_VALUE .Industry_value_section .row .col-md-4 {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.INDUSTRY_BASED_VALUE .Industry_value_section .row .col-md-4 .Industry_value {
  border: 1px solid #8e8e8e;
  padding: 15px;
  border-radius: 16px;
  background: white;
}

.INDUSTRY_BASED_VALUE .Industry_value_section .row .col-md-4 .Industry_value h1 {
  font-family: "Roboto", serif;
  font-weight: 400;
}

.INDUSTRY_BASED_VALUE .Industry_value_section .row .col-md-4 .Industry_value p {
  font-family: "Roboto", serif;
  font-size: 1.3rem;
}

/* selected sectin main */
.Blog_selected_section_main {
  padding-top: 25px;
  padding-bottom: 25px;
}

.Blog_selected_section_main .container-fluid .row .col-md-4 {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.Blog_selected_section_main .container-fluid .row .col-md-4 select {
  width: 100%;
  height: 50px;
  outline: none;
  border-radius: 5px;
}

.Blog_selected_section_main .container-fluid .row .col-md-8 {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.Blog_selected_section_main .container-fluid .row .col-md-8 .Search_section {
  width: 100%;
  height: 50px;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 5px;
}

/* blog section */
.Blog_section_main {
  background: url("../public/Images/About_section_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Blog_secction_top_one {
  padding: 0;
  margin: 0;
  padding: 12px;
}

.Blog_secction_top_one .Blog_images_section {
  padding: 7px;
  font-family: "Roboto", serif;
  border: 1px solid #8e8e8e;
  border-radius: 16px;
}

.Blog_section_text_main p {
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
}

.Blog_secction_top_one .Blog_images_section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Blog_secction_top_one .Blog_images_section button {
  background: transparent;
  padding: 10px;
  font-weight: 400;
  border: none;
  color: #fbae16;
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.Blog_secction_top_one .Blog_images_section button .ArrowRightAltIcon {
  transition: transform 1s ease;
  padding-left: 0;
}

.Blog_secction_top_one .Blog_images_section button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.Blog_section_top_right {
  padding: 0;
  margin: 0;
  padding: 12px;
}

.Blog_section_top_right .Blog_images_section {
  padding: 7px;
  font-family: "Roboto", serif;
  border: 1px solid #8e8e8e;
  border-radius: 16px;
}

.Blog_section_top_right .Blog_images_section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.Blog_images_section button {
  background: transparent;
  padding: 2px;
  font-weight: 400;
  border: none;
  color: #fbae16;
  display: flex;
  align-items: center;
}

.Blog_section_top_right.Blog_images_section button .ArrowRightAltIcon {
  transition: transform 1s ease;
  padding-left: 0;
}

.Blog_section_top_right .Blog_images_section button:hover .ArrowRightAltIcon {
  transform: translateX(10px);
}

.div_section {
  padding: 13px;
}

.Blog_details_pages {
  padding-top: 30px;
  padding-bottom: 30px;
}

.Blog_details_pages .row .col-12 h6 {
  color: #fbae16;
  font-size: x-large;
  font-family: "Roboto", serif;
}

.Blog_details_pages .row .col-12 h4 {
  color: white;
  font-family: "Roboto", serif;
  font-size: xx-large;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.Blog_section_text {
  background: url("../public/Images/About_section_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 70px;
  padding-bottom: 70px;
}

.Blog_section_text .Blog_section_main_text {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.Blog_section_text .Blog_section_main_text p {
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;
}

.Blog_section_text .Blog_images_section {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.Blog_section_text .Blog_images_section img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 12px;
}

.Blog_images_section .container-fluid .row .Blog_deatisls_pages_images {
  padding: 0;
  margin: 0;
  height: 350px;
  padding: 10px;
}

.Blog_images_section .container-fluid .row .Blog_deatisls_pages_images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.Blog_sectio_text_details .container-fluid .row .col-12 {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.Blog_sectio_text_details .container-fluid .row .col-12 p {
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;
}

.text_section {
  background: url("../public/Images/About_section_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.text_section .container-fluid .row .col-12 {
  padding: 0;
  margin: 0;
  padding: 10px;
}

.text_section .container-fluid .row .col-12 h4 {
  color: #fbae16;
  font-weight: 400;
  font-family: "Roboto", serif;
}

.text_section .container-fluid .row .col-12 p {
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;
}

/* project section main */
.project_section_main .container-fluid .row .col-sm-4 {
  padding: 0;
  margin: 0;
  padding: 20px;
}

.project_section_main .container-fluid .row .col-sm-4 button {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  color: black;
  font-weight: 400;
  font-family: "Roboto", serif;
  font-size: x-large;
  border: 1px solid #fbae16;
  background: transparent;
}

.project_section_main .container-fluid .row .col-sm-4 button.active {
  width: 100%;
  height: 50px;
  background: #fbae16;
  border-radius: 8px;
  color: white;
  font-weight: 400;
  font-family: "Roboto", serif;
  font-size: x-large;
  border: none;
}

.Our_porjectes_section {
  padding: 20px;
  padding-bottom: 60px;
}

.Our_porjectes_section .Our_projects_items {
  height: 100%;
  padding: 15px;
  border-radius: 16px;
  box-shadow: rgba(149, 157, 165, 0.192) 0px 8px 24px;
}

.Our_porjectes_section .Our_projects_items img {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  border-radius: 12px;
}

.Our_porjectes_section .Our_projects_items button {
  padding: 6px;
  border: 1px solid #fbae16;
  border-radius: 20px;
  color: #fbae16;
  font-family: "Roboto", serif;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: transparent;
}

.Our_porjectes_section .Our_projects_items h4 {
  padding-top: 15px;
  font-weight: 400;
  font-family: "Roboto", serif;
  padding-bottom: 0;
}

.Our_porjectes_section .Our_projects_items p {
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;

}

.Our_porjectes_section .Our_projects_items .Area_images {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Our_porjectes_section .Our_projects_items .Area_images img {
  width: 25px;
}

.Our_porjectes_section .Our_projects_items h5 {
  font-weight: 400;
  font-family: "Roboto", serif;
}

.services_one_section .col-12 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services_one_section .col-12 h1 {
  font-weight: 400;
  font-family: "Roboto", serif;
}

.services_one_section .col-12 p {
  text-align: center;
  font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
  font-family: "Roboto", serif;
}

@media screen and (max-device-width: 952px) {
  .About_section_main_footer .About_Footer_section {
    background: #ecece7;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-bottom: 30px;
  }

  .About_section_main_footer .Submit_section {
    background: #ffffff;
    width: 50%;
    padding-top: 200px;
    padding-bottom: 30px;
  }

  .About_section_main_footer .Submit_section div {
    width: 180px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #fbae16;
    border-radius: 12px;
    color: white;
    cursor: pointer;
  }
}

@media screen and (max-device-width: 768px) {
  .Expreance_text_section {
    position: relative;
    padding-bottom: 10px;
  }

  .Expreanse_section_main {
    display: none;
  }

  .Hero_section_main {
    padding-top: 160px;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  .Hero_section_main_hero .Heading_hero_top_section h1 {
    font-size: clamp(1.6rem, 3vw + 2vh, 5.6rem);
    color: #ffffff;
    font-family: "Roboto", serif;
    font-weight: 800;
  }

  .Hero_section_main_hero .Heading_hero_top_section p {
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    color: #ffffff;
    padding: 10px;
    width: auto;
    font-family: "Roboto", serif;
    font-weight: 300;
    text-align: left;
  }

  /* About section coding start */
  .About_section_main {
    background: url("../public/Images/Images_Exprense_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 90px;
    padding-bottom: 100px;
  }

  .About_main_footer {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    flex-wrap: wrap;
  }

  .About_section_main_footer .About_Footer_section {
    background: #ecece7;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-bottom: 30px;
  }

  .About_section_main_footer .Submit_section {
    background: #ffffff;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 20px;
    padding-left: 10px;
  }

  .container-fluid .row .col-md-4 .Testimonial_images {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .Expreance_text_section h1 {
    font-size: clamp(1rem, 2.7vw + 2vh, 3.5rem);
    font-family: "Roboto", serif;
    letter-spacing: 1px;
    padding-top: 0px;
  }

  .Gallery_heding_section_main .container-fluid .row .col-md-6 p {
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    padding: 10px;
  }

  .container-fluid .row .col-md-8 h1 {
    font-size: clamp(1rem, 2.5vw + 2vh, 3.5rem);
    font-family: "Roboto", serif;
    padding-bottom: 1px;
    padding: 20px;
  }

  .container-fluid .row .col-md-8 p {
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    text-align: justify;
  }

  .Testimonial_section_slider .Testimonial_section_slider_items p {
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
  }

  .Blog_section_slider .Blog_section_slider_items .Dec_section_blog {
    font-family: "Roboto", serif;
    padding-bottom: 0;
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    padding-top: 0;
  }

  .FAQ_main_section .col-12 h5 {
    font-family: "Roboto", serif;
    padding-top: 20px;
    padding-bottom: 40px;
    font-weight: 700;
  }

  .Body_text_section p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", serif;
    text-align: justify;
    padding-bottom: 0;
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
  }

  .About_Footer_section h1 {
    font-family: "Roboto", serif;
    font-weight: 400;
    padding-top: 20px;
  }

  .About_Footer_section p {
    font-family: "Roboto", serif;
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
  }

  /* Gallery section main */
  .Gallery_heding_section_main {
    background: url("../public/Images/Gellary_bg_images.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .Brand_partner {
    padding-top: 10px;
  }

  .container-fluid .row .col-md-4 {
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
  }

  .container-fluid .row .col-md-12 {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 0px;
    color: red;
  }

  /* about main section  */



  .About_section_main .About_section_images {
    padding: 0;
    margin: 0;
    padding-top: 20px;
    border: 1px solid red;
  }

  .About_section_main_all .About_section_text {
    color: white;
  }

  .About_section_main_all .About_section_text h1 {
    font-size: clamp(1rem, 3vw + 2vh, 5.6rem);
    font-weight: 400;
  }

  .About_section_main_all .About_section_text p {
    font-family: "Roboto", serif;
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
  }

  .About_section_main_all .About_section_images {
    padding: 0;
    margin: 0;
    padding-top: 10px;
  }

  .About_section_main_secound .About_section_secound .row .col-12 {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
  }

  .About_section_main_secound .About_section_secound .row .col-12 h1 {
    font-size: clamp(1rem, 3vw + 2vh, 5.6rem);
    font-weight: 700;
    text-align: left;
  }

  .About_section_main_secound .About_section_secound .row .col-12 p {
    text-align: justify;
    padding: 10px;
    font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    padding-bottom: 20px;
  }

  .About_section_thard .About_section_th .row .col-md-6 p {
    font-size: clamp(1rem, 2vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    text-align: justify;
    padding: 10px;
  }

  .About_section_thard .About_section_th .row .col-md-6 p {
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    padding: 0px;
    text-align: justify;
    padding-bottom: 10px;
  }

  .About_section_thard .About_section_th .row .col-md-6 h1 {
    font-weight: 700;
    text-align: left;
    padding-top: 20px;
    padding: 0px;
  }

  .About_section_thard .About_section_th .row .col-md-6 img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    object-fit: cover;
    padding-left: 0px;
    padding-right: 0px;
  }

  .Our_team_section_main .row .col-12 .row .col-md-4 .Images_section .Images_section_all img {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-right: 20px;
    padding-left: 5px;
  }

  .Our_teams_section_main {
    background: url("../public/Images/Images_Exprense_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 10px;
  }

  .Footer_Main .Footer_column .About_footer_section {
    padding-left: 10px;
  }

  /* services section main */
  .Services_section_maain .Services_section .row .col-12 h1 {
    padding: 0;
    margin: 0;
    color: white;
    font-family: "Roboto", serif;
    padding: 10px;
    font-size: clamp(1rem, 3vw + 2vh, 5.6rem);
  }

  .Services_section_maain .Services_section .row .col-12 p {
    color: white;
    font-family: "Roboto", serif;
    padding: 10px;
    font-size: clamp(0.8rem, 0.6vw + 2vh, 1.3rem);
  }

  /* blog section main */
  .Blog_section_text_main p {
    font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
    text-align: justify;
  }

  .Blog_details_pages .row .col-12 h6 {
    color: #fbae16;
    font-size: x-large;
    font-family: "Roboto", serif;
    padding: 10px;
  }

  .Blog_details_pages .row .col-12 h4 {
    color: white;
    font-family: "Roboto", serif;
    font-size: xx-large;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    font-family: "Roboto", serif;
    padding: 10px;
    font-size: clamp(1rem, 3vw + 2vh, 5.6rem);
  }

  .Blog_section_text .Blog_section_main_text p {
    font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
    font-family: "Roboto", serif;
    text-align: justify;
  }

  .Blog_images_section .container-fluid .row .Blog_deatisls_pages_images {
    padding: 0;
    margin: 0;
    height: 350px;
    padding: 0px;
  }

  .Blog_sectio_text_details .container-fluid .row .col-12 p {
    font-size: clamp(1rem, 1vw + 2vh, 1.2rem);
    font-family: "Roboto", serif;
    text-align: justify;
  }

  .container-fluid .row .col-md-8 .Out_tem_info {
    font-size: clamp(1rem, 1vw + 2vh, 1.3rem);
    font-family: "Roboto", serif;
    padding-left: 10px;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-device-width: 576px) {
  .slider_full_body_section {
    height: 100vh;
    padding-bottom: 15vw;
  }

  .Expreanse_About_section_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .About_section_main_all .About_section_text button {
    background: #fbae16;
    border-radius: 8px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-family: "Roboto", serif;
    font-weight: 400;
    width: 100%;
  }
}

.accordion-item {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.accordion-header {
  padding: 15px;
  background-color: #f7f7f7;
  cursor: pointer;
}

.accordion-header:hover {
  background-color: #eaeaea;
}

.accordion-body {
  padding: 15px;
  background-color: #fafafa;
}

.Images_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Images_icons .Iconses {
  font-size: 40px;
}

.panel {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
  width: 170px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: all 700ms ease-in;
  flex: 0 1 170px;
  margin: 10px;
}

.panel:hover {
  transform: scale(1);
  filter: grayscale(0%);
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.panel.active h3 {
  opacity: 1;
}

.panel.active {
  flex: 0 1 450px;
  z-index: 10;
}

.gallory_new_all {
  display: flex;
  overflow: hidden;
}

@media (max-width: 768px) {
  .panel {
    width: 150px;
    height: 350px;
  }

  .panel:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 480px) {
  .container {
    width: 100vw;
    justify-content: space-between;
  }

  .panel {
    width: 100%;
    height: 300px;
  }

  .panel:nth-last-of-type(6),
  .panel:nth-last-of-type(7) {
    display: none;
  }
}

.custom-modal-shadow {
  font-family: "Roboto", serif;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: 1px solid white;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: 1px solid white;
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.custom-modal-shadow .modal-content {
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5),
    0 6px 20px rgba(255, 255, 255, 0.3);
  border: none;
}

.video_section {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -9999;
}

.text_overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9990909;
  width: 100%;
  height: 100%;
  background: rgba(7, 7, 7, 0.452);
}

.text_overlay .Over_text {
  border: 1px solid green;
  z-index: 9999999;
}

.text_section_heading {
  color: white;
  margin-top: 300px
}

.text_section_heading h2 {
  font-size: 75px;
  font-family: "Roboto", serif;
  font-weight: 400;
}

.text_section_heading p {
  color: yellow;
  padding-left: 0;
  margin-left: 0;
  font-size: 24px;
  width: 900px;
  ;
}


.Table_section_main {
  padding-bottom: 50px;

}

.Header_table_main {
  display: flex;
  justify-content: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Header_table_main h1 {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-size: 35px;
  padding-top: 30px;
}

.Heading___Disclaimer {
  font-family: "Roboto", serif;
}

ol li {
  font-family: "Roboto", serif;
  padding: 0;
  margin: 0;
}

.Co_spacee_section h2 {
  font-family: "Roboto", serif;
  font-weight: 600;
}

.Co_spacee_section ul {
  list-style: none;
  font-family: "Roboto", serif;
  padding: 0;
  margin: 0;
}

.Co_spacee_section ul li {
  font-size: 19px;

}

.slider-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-item {
  width: 25%;
  padding: 10px;
  box-sizing: border-box;
}

.slider-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}


.swiper-button-prev,
.swiper-button-next {
  color: #fbae16;
  /* Set button color */
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}





.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.896);
  padding: 9px;
  border-radius: 100%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

.Name__section {
  width: 40%;
  background: #253037;
  border-radius: 10px;
  color: white;
}