.affix {
  background: #253037;
  transition: 0.7s;
  padding-top: 10px;
  padding-bottom: 10px;
}

.affix .slider_section_nav {
  display: none;
}

.affix .nav-links .drop-menu {
  background: #253037;
}

.affix .nav-links li.active a {
  color: #fbae16;
  font-weight: bold;
  background: #313c42;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.affix .Cout_out_section {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: -10px;
  background: #253037;
  border-top-left-radius: 100px;
}

.container-fluid {
  background: transparent;
}

.navbar_main {
  position: fixed;
  top: 0;
  z-index: 100000;
  width: 100%;
}

.Navbar_section_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.logo {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.logo img {
  width: 9rem;
  /* change 6 to 9  */
}

.nav-links {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;
  padding-top: 1rem;
}

.nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Roboto", serif;
}

.Services_link:hover {
  color: #fbae16;
}

.nav-links li a:hover {
  color: #fbae16;
  cursor: pointer;
}

.nav-links li {
  position: relative;
}

.nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  position: absolute;
  transition: all ease-in-out 250ms;
  margin: 0 0 0 10%;
}

.nav-links li a:hover::before {
  width: 80%;
}

.login-button {
  background-color: #fbae16;
  border-radius: 0.5rem;
  padding: 0.6rem 1.4rem;
  margin-left: 2vw;
  font-size: 1rem;
  font-family: "Roboto", serif;
  font-weight: 600;
  cursor: pointer;
  border: none;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button:hover {
  background: transparent;
  color: #fbae16;
  border: 1px solid #fbae16;
  transition: all ease-in-out 350ms;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-container {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

nav .wrapper {
  position: relative;
  padding-top: 5px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .nav-links {
  display: inline-flex;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size:18px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  padding-left: 0px;
  line-height: 45px;
  width: 100%;
  z-index: 9999;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.Cout_out_section {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: -10px;
  background: #242526;
  border-top-left-radius: 100px;
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: #242526;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}

.row .mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}

.nav-links li.active a {
  color: #fbae16;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}


/* footer start */
.Footer_Main {
  background: #253037;
}

.Footer_Main .Footer_column .About_footer_section {
  padding: 20px;
}

.Footer_Main .Footer_column .About_footer_section p {
  padding-top: 12px;
  color: #ffffff;
  font-family: "Roboto", serif;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.Footer_Main .Footer_column .Contact_footer_section {
  padding: 20px;
}

.Footer_Main .Footer_column .Contact_footer_section h1 {
  font-size: 2rem;
  font-family: "Roboto", serif;
  color: #ecece7;
}

.Footer_Main .Footer_column .Contact_footer_section .Fa_icons_section_footer {
  display: flex;
  gap: 10px;
  font-family: "Roboto", serif;
  color: #ecece7;
  font-size: 1.2rem;
}

.Footer_Main .Footer_column .Follow_section_Footer {
  padding: 20px;
}

.Footer_Main .Footer_column .Follow_section_Footer h1 {
  font-size: 2rem;
  font-family: "Roboto", serif;
  color: #ecece7;
}

.Footer_Main .Footer_column .Follow_section_Footer .Fa_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size:1.5rem;
  padding-right:50px;
}

.Footer_Main .Footer_column .Follow_section_Footer .Fa_section i {
  padding:10px;
  border-radius: 12px;
  background: #131418;
  cursor: pointer;
}

.Footer_Main .Footer_column .Follow_section_Footer .Fa_section i:hover {
  color: #253037;
  background: white;
  transition: 1s;
}

.Policay_section_main {
  padding: 0;
  margin: 0;
}

.Policay_section_main p {
  color: white;
}

.copy_right_section {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-top: 1px solid #a8a4a4;
  color: #ffffff;
  font-family: "Roboto", serif;
  font-size: 1.2rem;
}

.footer_images {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  padding-left: 30px;
}

.footer_images p {
  color: white;
  font-family: "Roboto", serif;
}

/* Footer _section coding start */
@media screen and (min-device-width: 768px) {
  .Containe_section_top_main {
    background: #253037;
    padding-bottom: 40px;
  }

  .Containe_section_top_main .Contat_main_section_footer {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .Containe_section_top_main .Images_section_contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .Containe_section_top_main .Images_section_contact img {
    width: 280px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    position: absolute;
  }

  .Containe_section_top_main .Contact_form {
    background: #ecece7;
    padding-bottom: 40px;
    padding-right: 70px;
    border-radius: 8px;
  }

  .Contact_form h1 {
    font-family: "Roboto", serif;

    letter-spacing: 1px;
    text-align: center;
    padding-top: 10px;
    color: white;
    padding-bottom: 10px;
  }

  .Form_section {
    padding-left: 170px;
  }

  .Form_name_section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .Form_section .Form_name_section .Name_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
  }

  .Form_section .Form_name_section .Name_section input {
    padding: 7px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    outline: none;
    font-family: "Roboto", serif;
  }

  .Form_section .Form_name_section .Name_section textarea {
    padding: 7px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    outline: none;
    font-family: "Roboto", serif;
  }

  .Form_section .Submit {
    width: 170px;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: #253037;
    cursor: pointer;
    color: white;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
  }

  .Form_section .Submit:hover {
    width: 170px;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #253037;
    background: transparent;
    cursor: pointer;
    color: #253037;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
    transition: 1s;
  }

  .Form_section .Submit:hover .NorthEastIcon {
    transition: transform 0.5s ease;
    transform: rotate(45deg);
  }
}

@media screen and (max-device-width: 768px) {
  .container-fluid {
    background: transparent;
  }

  .navbar_main {
    position: fixed;
    top: 0;
    z-index: 9999999;
    width: 100%;
  }

  .Navbar_section_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .logo {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .logo img {
    width: 6rem;
  }

  .nav-links {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    padding-top: 1rem;
  }

  .nav-links li a {
    text-decoration: none;
    margin: 0 0.7vw;
    color: rgb(255, 255, 255);
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Roboto", serif;
  }

  .nav-links li a:hover {
    color: #fbae16;
    background: #3a3b3c;
  }

  .nav-links li {
    position: relative;
  }

  .nav-links li a::before {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
  }

  .nav-links li a:hover::before {
    width: 80%;
  }

  .login-button {
    background-color: #fbae16;
    border-radius: 0.5rem;
    padding: 0.6rem 1.4rem;
    margin-left: 2vw;
    font-size: 1rem;
    font-family: "Roboto", serif;
    font-weight: 600;
    cursor: pointer;
    border: none;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .login-button:hover {
    background: transparent;
    color: #fbae16;
    border: 1px solid #fbae16;
    transition: all ease-in-out 350ms;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slider-container {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  /* Footer _section coding start */
  .Containe_section_top_main {
    background: #253037;
    padding-bottom: 40px;
  }

  .Containe_section_top_main .Contat_main_section_footer {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .Containe_section_top_main .Images_section_contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .Containe_section_top_main .Images_section_contact img {
    width: 280px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    position: absolute;
  }

  .Containe_section_top_main .Contact_form {
    background: #ecece7;
    padding-bottom: 40px;
    padding-right: 10px;
    width: 100%;
  }

  .Contact_form h1 {
    font-family: "Roboto", serif;
    font-size: 3.6rem;
    letter-spacing: 1px;
    padding-left: 10px;
    padding-top: 30px;
  }

  .Form_section {
    padding-left: 10px;
  }

  .Form_name_section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }

  .Form_section .Form_name_section .Name_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
  }

  .Form_section .Form_name_section .Name_section input {
    padding: 7px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    outline: none;
    font-family: "Roboto", serif;
  }

  .Form_section .Form_name_section .Name_section textarea {
    padding: 7px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: transparent;
    outline: none;
    font-family: "Roboto", serif;
  }

  .Form_section .Submit {
    width: 170px;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: #253037;
    cursor: pointer;
    color: white;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
  }

  .Form_section .Submit:hover {
    width: 170px;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #253037;
    background: transparent;
    cursor: pointer;
    color: #253037;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
    transition: 1s;
  }

  .Form_section .Submit:hover .NorthEastIcon {
    transition: transform 0.5s ease;
    transform: rotate(45deg);
  }

  .Containe_section_top_main .Images_section_contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    display: none;
  }

  .Containe_section_top_main .Images_section_contact img {
    width: 280px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    position: absolute;
    display: none;
  }

  /* footer start */
  .Footer_Main {
    background: #253037;
  }

  .Footer_Main .Footer_column .About_footer_section {
    padding: 20px;
  }

  .Footer_Main .Footer_column .About_footer_section p {
    padding-top: 12px;
    color: #ffffff;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }

  .Footer_Main .Footer_column .Contact_footer_section {
    padding: 20px;
  }

  .Footer_Main .Footer_column .Contact_footer_section h1 {
    font-size: 3rem;
    font-family: "Roboto", serif;
    font-weight: 400;
  }

  .Footer_Main .Footer_column .Contact_footer_section .Fa_icons_section_footer {
    display: flex;
    gap: 10px;
    font-family: "Roboto", serif;
    color: #ecece7;
    font-size: 1.2rem;
  }

  .Footer_Main .Footer_column .Follow_section_Footer {
    padding: 20px;
  }

  .Footer_Main .Footer_column .Follow_section_Footer h1 {
    font-size: 3rem;
    font-family: "Roboto", serif;
    color: #ecece7;
    font-weight: 400;
  }

  .Footer_Main .Footer_column .Follow_section_Footer .Fa_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 20px;
    font-size: 2.3rem;
  }

  .Footer_Main .Footer_column .Follow_section_Footer .Fa_section i {
    padding: 6px;
    border-radius: 12px;
    background: #131418;
    cursor: pointer;
  }

  .Footer_Main .Footer_column .Follow_section_Footer .Fa_section i:hover {
    color: #253037;
    background: white;
    transition: 1s;
  }

  .copy_right_section {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-top: 1px solid #a8a4a4;
    color: #ffffff;
    font-family: "Roboto", serif;
    font-size: 1.2rem;
  }
}



@media screen and (max-width: 970px) {
  .affix .Cout_out_section {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 0;
    top: -10px;
    background: transparent;
    border-top-left-radius: 100px;
  }

  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
    z-index: 9999;
  }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }

  #menu-btn:checked~.nav-links {
    left: 0%;
  }

  #menu-btn:checked~.btn.menu-btn {
    display: none;
  }

  #close-btn:checked~.btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDrop:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    padding-left: 20px;
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;

  }

  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }

  .Name__section {
    width: 100% !important;
    text-align: center;
    background: #253037;
    border-radius: 10px;
    padding-top: 0;
    padding-bottom: 20px;
    font-size: xx-large !important;
  }
}


#Footer_____section {
  padding-left: 200px;
  padding-right: 200px;
}

@media screen and (max-width: 1170px) {  /* Remove quotes */
  #Footer_____section {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

@media screen and (max-width:890px) {  /* Remove quotes */
  #Footer_____section {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
